import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userApi } from "../services/auth";

export interface AuthState {
  isLoggedIn: boolean;
  at?: string;
  rt?: string;
  user?: {};
}

const initialState: AuthState = {
  isLoggedIn: false
};

export interface TokenSet {
  at: string;
  rt: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<TokenSet>) => {
      state.at = action.payload.at;
      state.rt = action.payload.rt;
      state.isLoggedIn = true;
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      delete state.at;
      delete state.rt;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.logout.matchFulfilled, (state, { payload }) => {
      state.isLoggedIn = false;
      delete state.at;
      delete state.rt;
    });
  }
});

export const { setLoggedIn, setLoggedOut } = authSlice.actions;

export default authSlice.reducer;

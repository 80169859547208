import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { IRootState } from "../store";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/user/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as IRootState).auth.at;

      console.log(getState());

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST"
      })
    })
  })
});

export const { useLogoutMutation } = userApi;
